<content-loading-animation *ngIf="loading"></content-loading-animation>
<form id="form" #form="ngForm" (ngSubmit)="form.valid && !nameControl.invalid && !tagsControl.invalid && onSubmit()" *ngIf="!loading">
    <div class="title-bar">
        <div class="row justify-content-center">
            <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                <div class="title">
                    <h1 *ngIf="!isClone && !isEdit">
                        {{ "CREATE_NEW_UDP_RTP_TARGET" | translate }}
                    </h1>
                    <h1 *ngIf="isClone">{{ "CLONE_UDP_RTP_TARGET" | translate }}</h1>
                    <h1 *ngIf="isEdit">{{ "EDIT_UDP_RTP_TARGET" | translate }}</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="content-area">
        <div class="container-fluid">
            <!-- UDP/RTP -->
            <div class="row justify-content-center">
                <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                    <section id="info">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'INFO' | translate }}">
                                {{ "INFO" | translate }}
                            </h3>
                        </div>

                        <!-- Name -->
                        <app-input-generic-field
                            zmid="form_name"
                            [isRestartRequiredTip]="isEdit"
                            [duplicateName]="targetNames"
                            [formControl]="nameControl"
                            [textFieldName]="'NAME' | translate"
                            [disabled]="saving"
                            [isParentFormSubmitted]="form.submitted"
                            type="text"
                        ></app-input-generic-field>

                        <!-- Access Tags -->
                        <zx-access-tags-select
                            zmid="form_access-tags"
                            [formControl]="tagsControl"
                            [label]="'TAGS' | translate"
                            name="resource_tag_ids"
                            type="delivery"
                            [isParentFormSubmitted]="form.submitted"
                        ></zx-access-tags-select>

                        <!-- Alerting Profile -->
                        <div class="form-group">
                            <label for="alertingProfile" [ngClass]="{ 'is-invalid': form.submitted && form.controls.alertingProfile?.errors }">{{
                                "ALERTING_PROFILE" | translate
                            }}</label>
                            <zx-alerting-profile-select
                                name="alertingProfile"
                                [(model)]="target.alertingProfile"
                                [ngClass]="{ 'is-invalid': form.submitted && form.controls.alertingProfile?.errors }"
                            ></zx-alerting-profile-select>
                        </div>
                        <!-- Location -->
                        <div class="form-group">
                            <label for="location_text"
                                >{{ "LOCATION" | translate
                                }}<fa-icon icon="info-circle" [ngbTooltip]="LocationContent" triggers="hover click" [closeDelay]="500"></fa-icon>
                                <ng-template #LocationContent>{{ "TOOLTIP.TARGET_LOCATION" | translate }}</ng-template></label
                            >
                            <zx-location-search [(model)]="target.location.address"></zx-location-search>
                        </div>
                    </section>

                    <section id="config">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'CONFIGURATION' | translate }}">
                                {{ "CONFIGURATION" | translate }}
                            </h3>
                        </div>
                        <!-- Channel Type -->
                        <fieldset class="form-group">
                            <legend for="subtype">
                                {{ "CHANNEL_TYPE" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                            </legend>
                            <mat-button-toggle-group
                                name="subtype"
                                aria-label="subtype"
                                [(ngModel)]="channelType"
                                (change)="resetChannelId()"
                                [multiple]="false"
                            >
                                <mat-button-toggle [value]="'passthrough_channel'">{{ "ZM_CHANNEL" | translate }}</mat-button-toggle>
                                <mat-button-toggle [value]="'mediaconnect_flow'">{{ "AWS_MEDIACONNECT_FLOW" | translate }}</mat-button-toggle>
                                <mat-button-toggle [value]="'medialive_channel'">{{ "MEDIALIVE" | translate }}</mat-button-toggle>
                            </mat-button-toggle-group>
                        </fieldset>
                        <!-- Channel Select -->
                        <div class="form-group">
                            <label for="delivery_channel_select">
                                {{ "CHANNEL" | translate
                                }}<fa-icon icon="info-circle" [ngbTooltip]="COContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                ><ng-template #COContent
                                    ><p class="mb-0">
                                        {{ "TOOLTIP.TARGET_CHANNEL_OPTIONAL" | translate }}
                                    </p></ng-template
                                ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                            </label>
                            <zx-delivery-channel-select
                                id="delivery_channel_select"
                                name="delivery_channel_select"
                                [(model)]="selectedChannelID"
                                [clearable]="true"
                                [failover]="true"
                                *ngIf="channelType === 'passthrough_channel'"
                            ></zx-delivery-channel-select>
                            <zx-mediaconnect-flow-select
                                id="mediaconnect_flow_select"
                                name="mediaconnect_flow_select"
                                [(model)]="selectedChannelID"
                                [clearable]="true"
                                [filter]="udpRtpTargetCompatibleFlows()"
                                (modelChange)="AWSflowSelected()"
                                *ngIf="channelType === 'mediaconnect_flow'"
                            ></zx-mediaconnect-flow-select>
                            <zx-medialive-channel-select
                                id="medialive_select"
                                name="medialive_select"
                                [(model)]="selectedChannelID"
                                [clearable]="true"
                                *ngIf="channelType === 'medialive_channel'"
                            ></zx-medialive-channel-select>
                        </div>

                        <!-- Source preference -->
                        <div *ngIf="channelType === 'passthrough_channel'" class="form-group">
                            <label for="source_preference">
                                {{ "SOURCE_PREFERENCE" | translate }}
                            </label>
                            <zx-delivery-channel-source-select
                                id="preferred_source"
                                name="preferred_source"
                                [channel_id]="selectedChannelID"
                                [(model)]="target.preferred_source"
                                [disabled]="!selectedChannelID"
                            >
                            </zx-delivery-channel-source-select>
                        </div>

                        <!-- VPC on flow selection -->
                        <div *ngIf="vpcNamesOnFlow && selectedChannelID && channelType === 'mediaconnect_flow'" class="form-group">
                            <label for="aws_flow_vpc_id"> {{ "VPC" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon> </label>
                            <ng-select
                                id="aws_flow_vpc_id"
                                name="aws_flow_vpc_id"
                                [items]="vpcNamesOnFlow"
                                [clearable]="true"
                                placeholder="{{ 'SELECT' | translate }} {{ 'VPC_INTERFACE_NAME' | translate }}"
                                [(ngModel)]="selectedFlowVPCName"
                                #awsFlowVPCSelection="ngModel"
                            >
                            </ng-select>
                        </div>

                        <!-- Host/IP & Port -->
                        <div class="row mb-3">
                            <div class="col-6">
                                <label for="target_host" [ngClass]="{ 'is-invalid': form.submitted && target_host.errors }"
                                    >{{ "HOST/IP" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                    ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                ></label>
                                <input
                                    type="text"
                                    id="target_host"
                                    name="target_host"
                                    placeholder="{{ 'HOST/IP' | translate }}"
                                    class="form-control"
                                    [ngClass]="{ 'is-invalid': form.submitted && target_host.errors }"
                                    [(ngModel)]="target.host"
                                    required
                                    #target_host="ngModel"
                                />
                                <div *ngIf="target_host.invalid" class="invalid-feedback">
                                    <div *ngIf="target_host.errors.required">{{ "HOST/IP" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                </div>
                            </div>
                            <div class="col-6">
                                <label for="port" [ngClass]="{ 'is-invalid': form.submitted && port.errors }"
                                    >{{ "PORT" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                    ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                ></label>
                                <input
                                    type="number"
                                    id="port"
                                    name="port"
                                    placeholder="{{ 'PORT' | translate }}"
                                    class="form-control"
                                    [ngClass]="{ 'is-invalid': form.submitted && port.errors }"
                                    [(ngModel)]="target.port"
                                    #port="ngModel"
                                    required
                                    [min]="0"
                                    [max]="65535"
                                />
                                <div *ngIf="port.invalid" class="invalid-feedback">
                                    <div *ngIf="port.errors.required">{{ "PORT" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                    <div *ngIf="port.errors.min || port.errors.max">
                                        {{ "PORT" | translate }} {{ "MUST_BE_BETWEEN_0_AND_65535" | translate }}.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- CIDR & TTL -->
                        <div class="row mb-3" *ngIf="channelType !== 'medialive_channel'">
                            <div class="col-6">
                                <label for="bind_cidr" [ngClass]="{ 'is-invalid': form.submitted && bind_cidr.errors }"
                                    >{{ "BIND_TO_LOCAL_NIC_BY_CIDR" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                ></label>
                                <input
                                    type="text"
                                    id="bind_cidr"
                                    name="bind_cidr"
                                    placeholder="e.g. 192.168.15.10/32"
                                    class="form-control"
                                    [ngClass]="{ 'is-invalid': form.submitted && bind_cidr.errors }"
                                    [(ngModel)]="target.bind_cidr"
                                    pattern="^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/(3[0-2]|[12]?[0-9]))$"
                                    #bind_cidr="ngModel"
                                />
                                <div *ngIf="bind_cidr.invalid" class="invalid-feedback">
                                    <div *ngIf="bind_cidr.errors.pattern">{{ "CIDR" | translate }} {{ "INVALID" | translate }}.</div>
                                </div>
                            </div>
                            <div class="col-6">
                                <label for="ttl" [ngClass]="{ 'is-invalid': form.submitted && ttl.errors }"
                                    >{{ "TTL" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                ></label>
                                <input
                                    type="number"
                                    id="ttl"
                                    name="ttl"
                                    placeholder="{{ 'TTL' | translate }}"
                                    class="form-control"
                                    [ngClass]="{ 'is-invalid': form.submitted && ttl.errors }"
                                    [(ngModel)]="target.ttl"
                                    #ttl="ngModel"
                                />
                                <div *ngIf="ttl.invalid" class="invalid-feedback"></div>
                            </div>
                        </div>
                        <!-- RTP -->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="rtp" name="rtp" [(ngModel)]="target.rtp" />
                                <label class="form-check-label" for="rtp"
                                    >{{ "RTP" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                ></label>
                            </div>
                        </div>
                        <!-- Resolution -->
                        <fieldset class="bordered mb-3" *ngIf="target.rtp">
                            <legend>{{ "SMPTE_2022_FEC" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon></legend>
                            <fieldset>
                                <mat-button-toggle-group name="smpte_2022_fec" aria-label="smpte 2022 fec" [(ngModel)]="target.smpte_2022_fec">
                                    <mat-button-toggle [value]="0">{{ "DISABLED" | translate }}</mat-button-toggle>
                                    <mat-button-toggle [value]="1">{{ "1D" | translate }}</mat-button-toggle>
                                    <mat-button-toggle [value]="2">{{ "2D" | translate }}</mat-button-toggle>
                                </mat-button-toggle-group>
                            </fieldset>
                            <div class="row mt-3" *ngIf="target.smpte_2022_fec">
                                <div class="col-6">
                                    <div class="input-group align-items-center">
                                        <div class="input-group-prepend">
                                            <label
                                                class="me-2"
                                                for="smpte_2022_fec_rows"
                                                [ngClass]="{ 'is-invalid': form.submitted && smpte_2022_fec_rows.errors }"
                                                >{{ "ROWS_D" | translate }}</label
                                            >
                                        </div>
                                        <div class="input-group-div">
                                            <input
                                                type="number"
                                                class="form-control w-100"
                                                id="smpte_2022_fec_rows"
                                                name="smpte_2022_fec_rows"
                                                [(ngModel)]="target.smpte_2022_fec_rows"
                                                required
                                                [ngClass]="{ 'is-invalid': form.submitted && smpte_2022_fec_rows.errors }"
                                                #smpte_2022_fec_rows="ngModel"
                                            />
                                            <div *ngIf="smpte_2022_fec_rows.invalid" class="invalid-feedback">
                                                <div *ngIf="smpte_2022_fec_rows.errors.required">
                                                    {{ "ROWS_D" | translate }} {{ "IS_REQUIRED" | translate }}.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="input-group align-items-center">
                                        <div class="input-group-prepend">
                                            <label
                                                class="me-2"
                                                for="smpte_2022_fec_cols"
                                                [ngClass]="{ 'is-invalid': form.submitted && smpte_2022_fec_cols.errors }"
                                                >{{ "COLUMNS_L" | translate }}</label
                                            >
                                        </div>
                                        <div class="input-group-div">
                                            <input
                                                type="number"
                                                class="form-control w-100"
                                                id="smpte_2022_fec_cols"
                                                name="smpte_2022_fec_cols"
                                                [(ngModel)]="target.smpte_2022_fec_cols"
                                                required
                                                [ngClass]="{ 'is-invalid': form.submitted && smpte_2022_fec_cols.errors }"
                                                #smpte_2022_fec_cols="ngModel"
                                            />
                                            <div *ngIf="smpte_2022_fec_cols.invalid" class="invalid-feedback">
                                                <div *ngIf="smpte_2022_fec_cols.errors.required">
                                                    {{ "COLUMNS_L" | translate }} {{ "IS_REQUIRED" | translate }}.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <div *ngIf="channelType === 'passthrough_channel'" class="form-group">
                            <label for="smoothing_input" [ngClass]="{ 'is-invalid': form.submitted && smoothing_input.errors }">
                                {{ "SMOOTHING" | translate }} [ms]<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                            </label>
                            <input
                                type="number"
                                id="smoothing_input"
                                name="smoothing_input"
                                placeholder="{{ 'SMOOTHING' | translate }} [ms]"
                                class="form-control"
                                pattern="^(0|[1-9][0-9]*)$"
                                [(ngModel)]="target.smoothing"
                                [ngClass]="{ 'is-invalid': form.submitted && smoothing_input.errors }"
                                #smoothing_input="ngModel"
                            />
                            <div *ngIf="smoothing_input.invalid" class="invalid-feedback">
                                <div *ngIf="smoothing_input.errors.pattern">{{ "SMOOTHING" | translate }} {{ "MUST_BE_A_POSITIVE_INTEGER" | translate }}.</div>
                            </div>
                        </div>
                        <!-- Remux Bitrate -->
                        <div class="form-group" *ngIf="channelType !== 'medialive_channel'">
                            <label for="remux_kbps" [ngClass]="{ 'is-invalid': form.submitted && remux_kbps.errors }"
                                >{{ "REMUX_BITRATE" | translate }} [kbps]<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                            ></label>
                            <input
                                type="number"
                                id="remux_kbps"
                                name="remux_kbps"
                                placeholder="{{ 'REMUX_BITRATE' | translate }} [kbps]"
                                class="form-control"
                                pattern="^\d+$"
                                [(ngModel)]="target.remux_kbps"
                                [ngClass]="{ 'is-invalid': form.submitted && remux_kbps.errors }"
                                #remux_kbps="ngModel"
                            />
                            <div *ngIf="remux_kbps.invalid" class="invalid-feedback">
                                <div *ngIf="remux_kbps.errors.pattern">{{ "REMUX_BITRATE" | translate }} {{ "MUST_BE_A_POSITIVE_INTEGER" | translate }}.</div>
                            </div>
                        </div>
                        <!-- Remux Mode -->
                        <fieldset class="form-group" *ngIf="channelType !== 'medialive_channel'">
                            <legend for="remux_mode">
                                {{ "REMUX_MODE" | translate }}
                            </legend>
                            <mat-button-toggle-group name="remux_mode" aria-label="remux mode" [(ngModel)]="target.remux_mode">
                                <mat-button-toggle value="pad" [disabled]="!target.remux_kbps">{{ "PAD" | translate }}</mat-button-toggle>
                                <mat-button-toggle value="remux" [disabled]="!target.remux_kbps">{{ "REMUX" | translate }}</mat-button-toggle>
                            </mat-button-toggle-group>
                        </fieldset>
                        <!-- PID Mapping Profile -->
                        <div class="form-group" *ngIf="channelType === 'passthrough_channel'">
                            <label for="pidMappingProfile" [ngClass]="{ 'is-invalid': form.submitted && form.controls.pidMappingProfile?.errors }"
                                >{{ "PID_MAPPING_PROFILE" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                            ></label>
                            <zx-pid-mapping-profile-select
                                name="pidMappingProfile"
                                [(model)]="pid_mapping_profile_id"
                                [ngClass]="{ 'is-invalid': form.submitted && form.controls.pidMappingProfile?.errors }"
                            ></zx-pid-mapping-profile-select>
                            <div *ngIf="form.controls.pidMappingProfile?.invalid" class="invalid-feedback">
                                <div *ngIf="form.controls.pidMappingProfile?.errors">{{ "PID_MAPPING_PROFILE" | translate }} {{ "ERROR" | translate }}.</div>
                            </div>
                        </div>

                        <!-- Transcoding Profile -->
                        <div class="form-group" *ngIf="channelType === 'medialive_channel'">
                            <label for="transcodingProfile" [ngClass]="{ 'is-invalid': form.submitted && form.controls.transcodingProfile?.errors }"
                                >{{ "TRANSCODING_PROFILE" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                ><fa-icon *ngIf="isEdit" icon="redo" size="sm" [ngbTooltip]="RestartContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                ><ng-template #RestartContent>{{ "RESTART_REQUIRED" | translate }}</ng-template></label
                            >
                            <zx-transcoding-profile-select
                                name="transcodingProfile"
                                [(model)]="transcodeProfile"
                                [required]="true"
                                [ngClass]="{ 'is-invalid': form.submitted && form.controls.transcodingProfile?.errors }"
                            ></zx-transcoding-profile-select>
                        </div>
                    </section>

                    <!-- State -->
                    <section id="state" *ngIf="!isEdit">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'INITIAL_STATE' | translate }}">
                                {{ "INITIAL_STATE" | translate }}
                            </h3>
                        </div>
                        <!-- Enabled -->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="is_enabled" name="is_enabled" [(ngModel)]="startDisabled" />
                                <label class="form-check-label" for="is_enabled">{{ "DISABLED" | translate }}</label>
                            </div>
                        </div>
                        <!-- Muted -->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="muted" name="muted" [(ngModel)]="target.muted" />
                                <label class="form-check-label" for="muted">{{ "MUTED" | translate }}</label>
                            </div>
                        </div>
                    </section>
                    <app-advanced-section-form
                        [showMTUField]="true"
                        [showBilling]="true"
                        [showExternalID]="true"
                        [isTarget]="true"
                        [model]="target"
                        [form]="form"
                        [isEdit]="isEdit"
                    ></app-advanced-section-form>

                    <app-error [marginBottom]="true" [marginTop]="false"></app-error>
                    <hr class="mt-0" />
                    <div class="text-end">
                        <button type="button" class="btn btn-outline-secondary float-start" (click)="back()" *ngIf="!isEdit && !isClone" [disabled]="saving">
                            <fa-icon icon="chevron-left" size="sm"></fa-icon>
                            {{ "BACK" | translate }}
                        </button>
                        <button type="button" class="btn btn-outline-secondary" (click)="cancel()" [disabled]="saving">
                            <fa-icon icon="times"></fa-icon>
                            {{ "CANCEL" | translate }}
                        </button>
                        <button
                            zmid="form-submit"
                            type="submit"
                            class="btn btn-primary ms-2"
                            [disabled]="saving"
                            [ngClass]="{ 'btn-danger': form.submitted && form.invalid }"
                        >
                            <fa-icon icon="check" size="sm"></fa-icon>
                            {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
